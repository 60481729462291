import axios from 'axios'
import date from 'date-and-time';
// import { prepareOrderRowHelper } from '../data/helpers/prepareOrderRowHelper'
// import { getAreaListApi } from './areaListApi'
// import { getCityListApi } from './cityListApi'
import { getManagerListApi } from './managerListApi'
// import orders from './importOrdersUA.json'
import db from './../firebase/firebase';
// import promocodesList from '../api/promocodesList.json'
import cityListData from '../old/cityListData.json'
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const addOrderApi = async (userData, managerList) => {

    const { token } = userData

    const resOrders = await getMonthOrderListApi(2021, 7, managerList)
    // const resOrders = await getOrderListShopApi()
    // console.log("🚀 ~ file: orderListApi.js ~ line 69 ~ addOrderApi ~ resOrders", resOrders)

    // Object.values(resOrders).forEach((item, index) => {


    //     axios
    //         .post('http://localhost:133/orders', {...item, locale: "uk-UA"},
    //             {
        //                 headers: {
            //                     Authorization: `Bearer ${token}`
    //                 }
    //             }).then(response => {
    //                 console.log(response);
    //             });
    // })
}
let cancelTokenSource;

export const getMonthOrderListApi = async (userData, year, month, brand) => {
    const { token, managerId, managerLocale } = userData
    // const noPromoForUA = managerLocale === "uk-UA" ? `promocode_ncontains=no orderPromocode` : ''
    let filterByBrand;
    switch (brand) {
        case 'all':
            filterByBrand = '';
            break;
        case 'FEETCALM':
            filterByBrand = `&productArticle_contains=${brand}`;
            break;
        case 'SONOMA':
            filterByBrand = `&productArticle_contains=${brand}`;
            break;
        case 'ELEGANT':
            filterByBrand = `&_where[0][productArticle_ncontains]=SONOMA&_where[1][productArticle_ncontains]=FEETCALM`;
            break;
        default:
            filterByBrand = [];
            break;
    }

    if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    // Create a new CancelToken
    cancelTokenSource = axios.CancelToken.source();

    try {
        const res = await axios
            .get(`${BACKEND_DOMAIN}/orders?_locale=${managerLocale}&manager=${managerId}&_limit=-1&orderYear=${year}&orderMonth=${month}${filterByBrand}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    cancelToken: cancelTokenSource.token
                }
            );
        return res;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            // Handle error
            console.error(error);
        }
    }
}


export const getAdminMonthOrderListApi = async (userData, year, month, location, brand) => {
    const { token } = userData

    let filterByBrand;
    switch (brand) {
        case 'all':
            filterByBrand = '';
            break;
        case 'SONOMA':
            filterByBrand = `&productArticle_contains=${brand}`;
            break;
        case 'FEETCALM':
            filterByBrand = `&productArticle_contains=${brand}`;
            break;
        case 'ELEGANT':
            filterByBrand = `&_where[0][productArticle_ncontains]=SONOMA&_where[1][productArticle_ncontains]=FEETCALM`;
            break;
        default:
            filterByBrand = [];
            break;
    }

    if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    // Create a new CancelToken
    cancelTokenSource = axios.CancelToken.source();

    try {
        const res = await axios.get(
            `${BACKEND_DOMAIN}/orders?_locale=${location}&_limit=-1&orderYear=${year}&orderMonth=${month}${filterByBrand}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: cancelTokenSource.token
            }
        );
        return res.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            // Handle error
            console.error(error);
        }
    }
}

// temporarily for upload cities
// export const addCitiesApi = async (userData) => {

//     const {token} = userData

//     Object.entries(cityListData).forEach((item, index) => {
// const city = item[0]
// const region = item[1]

//         axios
//             .post('http://localhost:1337/cities', {city: city, region: region, locale: "uk-UA"},
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 }).then(response => {
//                     console.log(response);
//                 });
//     })
// }