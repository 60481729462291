import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography, Divider } from '@material-ui/core';
import { yearList } from '../data/constants/dates';
import { brandList } from '../data/constants/selects';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  }
});

const ViewTableOrderList = (props) => {
  const { items, month, year, brand, handleChangeMonth, handleChangeYear, handleChangeBrand } = props

  const classes = useStyles();
  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="brand label">Бренд</InputLabel>
          <Select
            value={brand}
            onChange={handleChangeBrand}
          >
            {brandList.map(item => {
              if (item.value === 'all') return
              return <MenuItem key={`brand-${item.value}`} value={item.value}>{item.label}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Місяць</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Рік</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            {yearList.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>Номер замовлення</TableCell>
              <TableCell>Місяць</TableCell>
              <TableCell>Назва препарату</TableCell>
              <TableCell>К-сть</TableCell>
              <TableCell>Сума</TableCell>
              <TableCell>Місто</TableCell>
              <TableCell>Область</TableCell>
              <TableCell>Промокод</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  <Typography variant="body2"
                    style={{
                      maxWidth: 80,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    title={item.orderId}
                  >
                    {item.orderId}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.orderMonth}
                </TableCell>
                {item.orderId.includes('-n') ?
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 300,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {item.productTitle}
                    </Typography>
                  </TableCell> :
                  <TableCell component="th" scope="row">
                    {Object.values(item.products).map((product, index) => (
                      <>
                        <Typography
                          variant="body2"
                          style={{
                            maxWidth: 300,
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                          {product.title}. - {product.quantity} шт.
                        </Typography>
                        {Object.keys(item.products).length - 1 !== index && <Divider />}
                      </>
                    ))}
                  </TableCell>}
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 300,
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {item.productQuantity}
                  </Typography>
                </TableCell>
                {item.orderId.includes('-n') ? <TableCell component="th" scope="row">
                  {item.productTotalPrice}
                </TableCell> :
                  <TableCell component="th" scope="row">
                    {item.totalPrice}
                  </TableCell>}
                <TableCell component="th" scope="row">
                  <Typography style={{
                    maxWidth: 200,
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {item.city}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {item.region}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.promocode === 'no orderPromocode' ? "-" : item.promocode}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ViewTableOrderList