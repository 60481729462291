import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography, TextField, Divider } from '@material-ui/core';

import AdminProductQtyEditableField from './AdminProductQtyEditableField';
import { yearList } from '../data/constants/dates';
import { brandList } from '../data/constants/selects';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 95,
    marginLeft: 5,
    '& .MuiFormLabel-root': {
      fontSize: '0.9rem',
      marginTop: 5,
    },
  },
  adminOrdersSumStyle: {
    width: 250,
    marginLeft: 10,
    position: "relative",
    top: 17
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const ProductQtyList = (props) => {
  const { items, month, year, managerId, managerList, userData, uniqueProductQtySources, sourceData, managerSalesTotal, brand, setEditedData, handleChangeMonth, handleChangeYear, handleChangeManager, handleChangeSource, handleChangeBrand } = props

  const classes = useStyles();

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="brand label">Бренд</InputLabel>
          <Select
            value={brand}
            onChange={handleChangeBrand}
          >
            {brandList.map(item => {
              if (item.value === 'all') return
              return <MenuItem key={`brand-${item.value}`} value={item.value}>{item.label}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Місяць</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Рік</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            {yearList.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-disabled"
            label="Виконання: "
            value={`${items.totalСomplPercent} %`}
            color="secondary"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал магазин"
            id="val-shop-text"
            value={managerSalesTotal.shopSum}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал опт"
            id="val-clinics-text"
            value={managerSalesTotal.clinicSalesSum}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал аптеки"
            id="val-apteks-text"
            value={managerSalesTotal.aptekSalesSum}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Вал загал."
            id="val-total-text"
            value={managerSalesTotal.totalVal}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Бонус"
            id="val-bonus-text"
            value={managerSalesTotal.bonusRes}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Ставка"
            id="val-minOklad-text"
            value={managerSalesTotal.minOklad}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Загал. бонус"
            id="total-bonus-text"
            value={managerSalesTotal.totalBonus}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>Менеджер</TableCell>
              <TableCell>Артикул препарату</TableCell>
              {/* <TableCell>Название препарата</TableCell> TO DELETE */}
              <TableCell>Планова к-сть</TableCell>
              <TableCell>Факт. загал.</TableCell>
              <TableCell>Факт. магазин</TableCell>
              <TableCell>Факт. клініки</TableCell>
              <TableCell>Факт. аптеки</TableCell>
              <TableCell>Виконання плану (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.productQtyArr.sort((a, b) => a['productArticle'].localeCompare(b['productArticle'])).map((item) => {
              if (item.planQty === 0) return
              return (
                <TableRow key={`${item.id}-pqtyItem`}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2"
                      style={{
                        maxWidth: 100,
                        // whiteSpace: 'pre-wrap',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis'
                      }}
                      title={item.manager.managerName}
                    >
                      {item.manager.managerName}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2"
                      style={{
                        maxWidth: 200,
                        // whiteSpace: 'pre-wrap',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis'
                      }}
                      title={item.productArticle}
                    >
                      {item.productArticle}
                    </Typography>
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                  <Typography variant="body2"
                    style={{
                      maxWidth: 200,
                      // whiteSpace: 'pre-wrap',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis'
                    }}
                    title={item.productTitle}
                  >
                    {item.productTitle}
                  </Typography>
                </TableCell> */}
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 100,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {item.planQty}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 100,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {item.factQtyTotal}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 100,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {item.factQtyS}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 100,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {item.factQtyC}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 100,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {item.factQtyA}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      style={{
                        maxWidth: 100,
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {item.completionRateItem.toFixed(1)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ProductQtyList