export const doctorCategory = ['A', 'B', 'C', 'D']
export const specialistType = ['Косметолог', 'Дерматолог', 'Дерматолог-косметолог', 'Терапевт', 'Інше']
export const regionNameList = [
  'Київська',
  'Черкаська',
  "Вінницька",
  "Волинська",
  "Дніпропетровська",
  "Донецька",
  "Житомирська",
  "Закарпатська",
  "Запорізька",
  "Івано-Франківська",
  "Кіровоградська",
  "Луганська",
  "Львівська",
  "Миколаївська",
  "Одеська",
  "Полтавська",
  "Рівненська",
  "Сумська",
  "Тернопільська",
  "Харківська",
  "Херсонська",
  "Хмельницька",
  "Чернівецька",
  "Чернігівська",
];
export const brandList = [
  {
    value: 'all',
    label: 'Всі'
  },
  {
    value: 'ELEGANT',
    label: 'Elegant'
  },
  {
    value: 'FEETCALM',
    label: 'Feetcalm'
  },
  {
    value: 'SONOMA',
    label: 'Sonoma'
  }
]