import { calculateManagerBonusSum } from '../selectors/managerListSelector';

export const getSalesTotals = (selectedManagerId, data, selectedMonth, brand, year) => {
    let shopSum = 0;
    let aptekSalesSum = 0;
    let clinicSalesSum = 0;
    let minOklad = 0;
    let managerBonusPercent = 0;

    data.forEach(manager => {
        if (selectedManagerId && manager.managerId !== selectedManagerId) {
            return;
        }

        manager.monthlySales.forEach(sale => {
            if (selectedMonth && sale.month !== selectedMonth) {
                return;
            }
            managerBonusPercent = manager.managerBonusPercent;
            const shopSumItem = sale.saleSum + sale.otherSale;
            shopSum += shopSumItem || 0;
            aptekSalesSum += sale.aptekSale || 0;
            clinicSalesSum += sale.clinicSale || 0;
            minOklad += sale.minOklad || 0;
        });
    });

    const totalVal = shopSum + clinicSalesSum + aptekSalesSum;

    const bonusRes = calculateManagerBonusSum(
        { saleSum: shopSum, otherSale: 0, aptekSale: aptekSalesSum, clinicSale: clinicSalesSum },
        brand,
        year,
        selectedMonth,
        managerBonusPercent
    );

    const totalBonus = bonusRes + minOklad;

    return { shopSum, aptekSalesSum, clinicSalesSum, totalVal, minOklad, bonusRes, totalBonus };
};