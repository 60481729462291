import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { userDataSelector } from '../data/selectors/userDataSelector';
import { getAllManagerSaleListAction, getManagerSaleListAction, loadManagerListAction } from './../data/actions/managerListActions';
import { allManagerListSelector, managerListSalesTotalSumSelector, managerSalesTotalSumSelector } from './../data/selectors/managerListSelector';
import AdminProductQtyList from './../views/AdminProductQtyList';
import { productQtyListTableSelector } from '../data/selectors/productQtySelector';
import { uniqueProductQtyListSourcesSelector } from './../data/selectors/productQtySelector';
import { getProductQtyListAction, updateProductQtyAction } from '../data/actions/productQtyListActions';
import { addUserLogDateApi } from '../api/logDataApi';
import ProductQtyList from '../views/ProductQtyList';
import { getSalesTotals } from '../data/helpers/getSalesTotalsHelper';

const ProductPlanContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)

    const managerIdData = userData.managerId

    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const [month, setMonth] = useState(currentMonth === 12 ? 1 : currentMonth + 1)
    const [year, setYear] = useState(currentMonth === 12 ? currentYear + 1 : currentYear)
    const [sourceData, setSourceData] = useState(0)
    const [editedData, setEditedData] = useState({})

    const productQtyList = useSelector(productQtyListTableSelector(brand));
    const managerListSalesTotalSum = useSelector(managerSalesTotalSumSelector(year, brand))

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    const data = { userData, year, month, managerIdData, editedData }


    const managerSalesTotal = getSalesTotals(managerIdData, managerListSalesTotalSum, month, brand, year)

    useEffect(() => {
            localStorage.setItem('brand', JSON.stringify(brand));
        }, [brand])

    useEffect(() => {
        dispatch(getProductQtyListAction(data))
    }, [month, year, managerIdData])

    useEffect(() => {
        dispatch(getManagerSaleListAction(userData, year, brand))
        addUserLogDateApi(userData, '/productQtyListReport')
    }, [year, brand])

    return (
        // <div>111</div>
        <ProductQtyList
            items={productQtyList}
            month={month}
            year={year}
            managerId={managerIdData}
            userData={userData}
            sourceData={sourceData}
            managerSalesTotal={managerSalesTotal}
            brand={brand}
            setEditedData={setEditedData}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeBrand={handleChangeBrand}
        />
    )
}

export default ProductPlanContainer
