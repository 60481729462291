import React from 'react';
import { TextField, FormControl, MenuItem, Button, Paper } from '@material-ui/core';
import { monthList, yearList } from '../../data/constants/dates';
import { brandList } from '../../data/constants/selects';

const FilterPanel = ({
  managerFilter,
  setManagerFilter,
  managerList,
  yearFilter,
  monthFilter,
  setYearFilter,
  setMonthFilter,
  allRegions,
  regionFilter,
  setRegionFilter,
  cityFilter,
  setCityFilter,
  greyZoneOrdersSumByRegion,
  filteredPromocodeOrdersSum,
  filteredBonusSum,
  showPreviousMonths,
  setShowPreviousMonths,
  brand,
  handleChangeBrand
}) => {
  const totalSales = greyZoneOrdersSumByRegion + filteredPromocodeOrdersSum
  const totalGreyZoneBonusByRegion = greyZoneOrdersSumByRegion ? Math.round(((brand === 'FEETCALM' ? 8 : 10) / 100) * (greyZoneOrdersSumByRegion)) : 0
  return (
    <Paper style={{ minWidth: '1200px', width: 'max-content' }}>
      <FormControl variant="outlined" margin="dense" style={{ width: '100px' }}>
        <TextField
          id="select"
          label="Бренд"
          variant="outlined"
          margin="dense"
          value={brand}
          title={brand}
          onChange={(e) => handleChangeBrand(e.target.value)}
          select
        >
          {brandList.map(item => {
            if (item.value === 'all') return
            return (
              <MenuItem
                key={`brand-${item.value}`} value={item.value}>{item.value}</MenuItem>
            )
          })}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ width: '160px' }}>
        <TextField
          id="select"
          label="Менеджер"
          variant="outlined"
          margin="dense"
          value={managerFilter}
          onChange={(e) => setManagerFilter(e.target.value)}
          select
        >
          {managerList.map(item => (
            <MenuItem
              key={item.id} value={item.id}>{item.managerName}</MenuItem>
          )
          )}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" margin="dense">
        <TextField
          label="Рік"
          variant="outlined"
          margin="dense"
          value={yearFilter}
          onChange={(e) => setYearFilter(e.target.value)}
          select
        >
          {yearList.map(year => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ marginRight: 5 }}>
        <TextField
          label="Місяць"
          variant="outlined"
          margin="dense"
          value={monthFilter}
          onChange={(e) => setMonthFilter(e.target.value)}
          select
        >
          {monthList.map(month => (
            <MenuItem key={month} value={month}>{month}</MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ width: '160px' }}>
        <TextField
          id="select"
          label="Область"
          variant="outlined"
          margin="dense"
          value={regionFilter}
          onChange={(e) => setRegionFilter(e.target.value)}
          select
        >
          {allRegions.map(region => (
            <MenuItem
              key={region} value={region}>{region}</MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ width: '100px' }}>
        <TextField
          label="Місто"
          variant="outlined"
          margin="dense"
          value={cityFilter}
          onChange={(e) => setCityFilter(e.target.value)}
          disabled
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ width: '180px' }}>
        <TextField
          label="Всього продаж по тер."
          variant="outlined"
          margin="dense"
          value={totalSales.toLocaleString('ru-RU')}
          disabled
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ width: '180px' }}>
        <TextField
          label="Сума сіра зона по тер."
          variant="outlined"
          margin="dense"
          value={greyZoneOrdersSumByRegion.toLocaleString('ru-RU')}
          disabled
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" style={{ width: '180px' }}>
        <TextField
          label="Бонус сіра зона по тер."
          variant="outlined"
          margin="dense"
          value={totalGreyZoneBonusByRegion.toLocaleString('ru-RU')}
          disabled
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense">
        <Button
          variant="contained"
          style={{ fontSize: 10, marginTop: 4 }}
          onClick={() => setShowPreviousMonths(!showPreviousMonths)}>
          {showPreviousMonths ? 'Сховати' : 'Показати'}{<br />} поп. місяці
        </Button>
      </FormControl>
    </Paper>
  );
};

export default FilterPanel;
