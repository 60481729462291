import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { userDataSelector } from '../data/selectors/userDataSelector';
import { getAllManagerSaleListAction, loadManagerListAction } from './../data/actions/managerListActions';
import { allManagerListSelector, managerListSalesTotalSumSelector } from './../data/selectors/managerListSelector';
import AdminProductQtyList from './../views/AdminProductQtyList';
import { productQtyListTableSelector, productSumQtyListTableSelector } from '../data/selectors/productQtySelector';
import { getProductQtyListAction, updateProductQtyAction } from '../data/actions/productQtyListActions';
import { addUserLogDateApi } from '../api/logDataApi';
import { getSalesTotals } from '../data/helpers/getSalesTotalsHelper';

const AdminProductPlanContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)

    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const [month, setMonth] = useState(currentMonth === 12 ? 1 : currentMonth + 1)
    const [year, setYear] = useState(currentMonth === 12 ? currentYear + 1 : currentYear)
    const [managerIdData, setManagerIdData] = useState(0)
    const [sourceData, setSourceData] = useState(0)
    const [editedData, setEditedData] = useState({})

    const managerList = useSelector(allManagerListSelector)
    const productQtyList = useSelector(managerIdData === "byProducts" ? productSumQtyListTableSelector(brand) : productQtyListTableSelector(brand));

    const productQtyListData = useMemo(() => productQtyList, [productQtyList]);

    const managerListSalesTotalSum = useSelector(managerListSalesTotalSumSelector(year, brand))

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }
    const handleChangeManager = (event) => {
        const newManagerIdData = event.target.value;
        setManagerIdData(newManagerIdData);
    };

    const handleChangeSource = (event) => {
        setSourceData(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    const modifiedManagerIdData = managerIdData === "byProducts" ? 0 : managerIdData

    const data = { userData, year, month, managerIdData: modifiedManagerIdData, editedData }

    const managerSalesTotal = getSalesTotals(managerIdData, managerListSalesTotalSum, month, brand, year)

    useEffect(() => {
        dispatch(loadManagerListAction(userData))
    }, [])

    useEffect(() => {
        localStorage.setItem('brand', JSON.stringify(brand));
    }, [brand])

    useEffect(() => {
        dispatch(getProductQtyListAction(data))
    }, [month, year, modifiedManagerIdData])

    useEffect(() => {
        if (editedData.id) {
            dispatch(updateProductQtyAction(data));
        }
    }, [editedData])

    useEffect(() => {
        dispatch(getAllManagerSaleListAction(userData, year, month, brand))
        addUserLogDateApi(userData, '/admin/productQtyListReport')
    }, [year, month, brand])


    return (
        // <div>111</div>
        <AdminProductQtyList
            items={productQtyListData}
            month={month}
            year={year}
            managerId={managerIdData}
            managerList={managerList}
            userData={userData}
            sourceData={sourceData}
            brand={brand}
            managerSalesTotal={managerSalesTotal}
            // isByProduct={isByProduct}
            setEditedData={setEditedData}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeManager={handleChangeManager}
            handleChangeSource={handleChangeSource}
            handleChangeBrand={handleChangeBrand}
        />
    )
}

export default AdminProductPlanContainer
