import { createSelector } from 'reselect'
import { userDataSelector } from './userDataSelector'
import { productQtyListTableMonthSelector } from './productQtySelector'

export const managerListSelector = (state) => state.managerList.managerList

export const managerSalesSelector = (state) => state.managerList.sales

export const allManagerListSelector = createSelector(
    [managerListSelector],
    (items) => {
        const result = [];
        const map = new Map();
        for (const item of items) {
            if (!map.has(item.id) && (item.manager_status == "main") || (item.manager_status == "secondary")) {
                map.set(item.id, true);
                result.push({
                    id: item.id,
                    managerName: item.managerName,
                    organizations: item.organizations
                });
            }
        }
        const allManagers = { id: 0, managerName: 'Всі менеджери' }
        result.unshift(allManagers)
        return result
    }
)


export const managerSalesTableSelector = createSelector(
    [managerSalesSelector],
    (managerSales) => {

        const res = managerSales.sort((a, b) => a.month > b.month)
        return res
    }
)

export const managerSalesFinLimitSelector = createSelector(
    [managerSalesTableSelector, userDataSelector],
    (managerSalesTable, userData) => {
        const { monthData, yearData } = userData.settingsData
        const managerLocale = userData.managerLocale
        if (managerSalesTable && monthData) {
            const filterList = (item) => {
                if (item.month === monthData) {
                    return item
                }
            }

            const res = managerSalesTable.find(filterList)

            const finLimit = res ? Math.round((10 / 100) * (res.otherSale)) : 0
            // const finLimitRU = res ? res.otherSale + res.aptekSale : 0

            return finLimit
        }
        return
    }
)

export const calculateManagerBonusSum = (curr, brand, year, month, managerBonusPercent) => {
    const isNewBonusApplicable =
        (brand === "FEETCALM" || brand === "SONOMA") &&
        (year > 2025 || (year === 2025 && month >= 1));

    let managerBonusSum;

    if (isNewBonusApplicable) {
        // Новая схема с февраля 2025
        console.log("🚀 ~ calculateManagerBonusSum ~ Новая схема с февраля 2025: month", month)
        const saleBonus = (curr.saleSum + curr.otherSale) * 0.05; // 5%
        const clinicBonus = curr.clinicSale * 0.025; // 2.5%
        managerBonusSum = Math.round(saleBonus + clinicBonus);
    } else {
        // Старая схема
        console.log("🚀 ~ calculateManagerBonusSum ~ Старая схема: month", month)
        const totalSaleSum = curr.saleSum + curr.otherSale + curr.aptekSale + curr.clinicSale;
        managerBonusSum = Math.round((managerBonusPercent / 100) * totalSaleSum);
    }

    return managerBonusSum;
};

export const managerListSalesTotalSumSelector = (year, brand) => createSelector(
    [managerListSelector, managerSalesTableSelector, productQtyListTableMonthSelector],
    (managerList, managerSalesTable, productQtyList) => {
        const resManagersSales = [];
        const threeManagers = [52, 57, 58];

        Object.values(managerList).forEach((item) => {
            const managerId = item.id;
            const managerName = item.managerName;
            let managerBonusPercent = item.bonusPercent;

            const managerSales = managerSalesTable.filter(sale => sale.manager.id === managerId);

            const findMinMonth = (sales) => {
                const minMonth = Math.min(...sales.map(sale => sale.month));
                return minMonth !== Infinity ? minMonth : 0;
            };

            const minMonth = findMinMonth(managerSales);

            for (let month = minMonth - 1; month > 0; month--) {
                const monthExists = managerSales.some(sale => sale.month === month && sale.year === year);
                if (!monthExists) {
                    managerSales.push({
                        month: month,
                        year: year,
                        saleSum: 0,
                        otherSale: 0,
                        aptekSale: 0,
                        clinicSale: 0,
                        bonusSum: 0,
                        minOklad: 0,
                        managerProfit: 0,
                        totalSaleSum: 0,
                        managerBonusSum: 0,
                    });
                }
            }

            const monthlySales = managerSales.reduce((acc, curr) => {
                if (curr.year === year) {
                    const month = curr.month;
                    const totalSaleSum = curr.saleSum + curr.otherSale + curr.aptekSale + curr.clinicSale;

                    const itemManagerId = Number(managerId);
                    const itemYear = Number(year);
                    const itemMonth = month;

                    let managerBonusSum = calculateManagerBonusSum(curr, brand, itemYear, itemMonth, managerBonusPercent);

                    // Доп. условие для трех менеджеров
                    if (threeManagers.includes(itemManagerId) && itemYear === 2024 && itemMonth < 9) {
                        managerBonusSum = 0;
                    }

                    const managerProfit = managerBonusSum + (curr.minOklad || 0);

                    const monthlyData = {
                        id: curr.id,
                        saleSum: curr.saleSum,
                        otherSale: curr.otherSale,
                        aptekSale: curr.aptekSale,
                        clinicSale: curr.clinicSale,
                        bonusSum: curr.bonusSum,
                        minOklad: curr.minOklad || 0,
                        managerProfit: managerProfit,
                        totalSaleSum: totalSaleSum,
                        managerBonusSum: managerBonusSum,
                        month: curr.month,
                    };

                    acc[month - 1] = monthlyData;
                }
                return acc;
            }, []);

            const managerItemSales = { managerId, managerName, monthlySales, managerBonusPercent };
            resManagersSales.push(managerItemSales);
        });
        return resManagersSales;
    }
);

export const managerSalesTotalSumSelector = (year, brand) => createSelector(
    [userDataSelector, managerSalesTableSelector],
    (userData, managerSalesTable) => {
        const resManagersSales = [];

        const managerId = userData.managerId;
        const managerBonusPercent = userData.managerBonusPercent;

        const managerSales = managerSalesTable.filter(sale => sale.manager.id === managerId);

        const monthlySales = managerSales.reduce((acc, curr) => {
            if (curr.year === year) {
                const month = curr.month;
                const totalSaleSum = curr.saleSum + curr.otherSale + curr.aptekSale + curr.clinicSale;

                let managerBonusSum = calculateManagerBonusSum(curr, brand, year, month, managerBonusPercent);

                const managerProfit = managerBonusSum + (curr.minOklad || 0);

                const monthlyData = {
                    id: curr.id,
                    saleSum: curr.saleSum,
                    otherSale: curr.otherSale,
                    aptekSale: curr.aptekSale,
                    clinicSale: curr.clinicSale,
                    bonusSum: curr.bonusSum,
                    minOklad: curr.minOklad || 0,
                    managerProfit: managerProfit,
                    totalSaleSum: totalSaleSum,
                    managerBonusSum: managerBonusSum,
                    month: curr.month,
                };

                acc[month - 1] = monthlyData;
            }
            return acc;
        }, []);

        const managerItemSales = { managerId, monthlySales, managerBonusPercent };
        resManagersSales.push(managerItemSales);

        return resManagersSales;
    }
);





export const managerItemSalesTotalSumSelector = (productQtyList, year, brand) => createSelector(
    [managerSalesTableSelector, userDataSelector],
    (managerSalesTable, userData) => {
        const resCalcSales = [];
        const threeManagers = [52, 57, 58];
        const managerId = Number(userData.managerId);
        const managerBonusPercent = userData.managerBonusPercent;

        // Функция для поиска минимального месяца
        const findMinMonth = (sales) => {
            const months = sales.map(sale => sale.month).filter(month => month > 0);
            return months.length ? Math.min(...months) : 1;
        };

        const minMonth = findMinMonth(managerSalesTable);

        // Заполнение отсутствующих месяцев в managerSalesTable
        for (let month = minMonth - 1; month > 0; month--) {
            if (!managerSalesTable.some(sale => sale.month === month && sale.year === year)) {
                managerSalesTable.push({
                    month,
                    year,
                    saleSum: 0,
                    otherSale: 0,
                    aptekSale: 0,
                    clinicSale: 0,
                    bonusSum: 0,
                    minOklad: 0,
                    managerProfit: 0,
                    totalSaleSum: 0,
                    managerBonusSum: 0,
                    brand: null, // добавил, чтобы избежать ошибок в проверке на brand
                });
            }
        }

        // Обход данных и расчет бонусов
        managerSalesTable.forEach((item) => {
            const totalSaleSum = item.saleSum + item.otherSale + item.aptekSale + item.clinicSale;
            const itemYear = Number(item.year);
            const itemMonth = item.month;

            let managerBonusSum = calculateManagerBonusSum(item, brand, itemYear, itemMonth, managerBonusPercent);

            // Доп. условие для трех менеджеров
            if (threeManagers.includes(managerId) && itemYear === 2024 && itemMonth < 9) {
                managerBonusSum = 0;
            }

            const managerProfit = managerBonusSum + item.minOklad;
            resCalcSales.push({
                ...item,
                totalSaleSum,
                managerBonusSum,
                managerProfit
            });
        });

        // Сортировка результатов по месяцам
        return resCalcSales.sort((a, b) => a.month - b.month);
    }
);